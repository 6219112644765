import { render, staticRenderFns } from "./ViewSharedDiagram.vue?vue&type=template&id=9624d3c4&"
import script from "./ViewSharedDiagram.vue?vue&type=script&lang=ts&"
export * from "./ViewSharedDiagram.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports